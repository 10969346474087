// SPACING SIZES
$xxs: 0.25rem;
$xs: 0.5rem;
$s: 1rem;
$m: 1.5rem;
$l: 2rem;
$xl: 2.5rem;
$xxl: 3rem;

// FONT SIZES
$font-base: $s; // body copy
$font-xs: 0.75rem;
$font-s: 0.875rem;
$font-m: $font-base;
$font-l: 1.125rem;
$font-xl: 1.25rem;
$font-2xl: 1.5rem;
$font-3xl: 1.875rem;
$font-4xl: 2.25rem;
$font-5xl: 3rem;

// FONT WEIGHT
$font-light: 300;
$font-normal: 400;
$font-regular: $font-normal;
$font-medium: 500;
$font-semibold: 600;
$font-bold: 700;
$font-extrabold: 800;

$border-radius: 0.375rem;
$border-radius-full: 999rem;
