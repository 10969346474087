.tab {
  display: inline-flex;
  padding: 0.5rem 0;
  align-items: center;

  &.withIndicator {
    border-bottom: 2px solid transparent;
    padding-bottom: calc(0.5rem - 2px);
    &[data-state="active"] {
      border-bottom: 2px solid theme("colors.slate.900") !important;
    }
  }
  &[data-state="active"] {
    color: theme("colors.slate.900");
  }
}

.withAccessory {
  display: flex;
  align-items: flex-end;
}

.tabWrapper {
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.violationsTabWrapper {
  &:not(:last-child) {
    margin-right: 0.5rem;
  }
}

.violationNotification {
  position: absolute;
  right: -2px;
  margin-top: 0.125rem;
}

.rainbowGradient {
  color: transparent;
  background-clip: text;
  -webkit-background-clip: text;
  background-size: 500% 100%;
  background-image: linear-gradient(
    45deg,
    theme("colors.content.tertiary"),
    theme("colors.content.tertiary"),
    theme("colors.content.tertiary"),
    #3b82f6,
    #c084fc,
    #db2777,
    theme("colors.content.tertiary"),
    theme("colors.content.tertiary"),
    theme("colors.content.tertiary")
  );
  animation: rainbowFlash 4s linear reverse infinite;
  &:hover {
    background-image: linear-gradient(
      45deg,
      theme("colors.content.primary"),
      theme("colors.content.primary"),
      theme("colors.content.primary"),
      #3b82f6,
      #c084fc,
      #db2777,
      theme("colors.content.primary"),
      theme("colors.content.primary"),
      theme("colors.content.primary")
    );
  }
  &[data-state="active"] {
    animation-iteration-count: 1;
  }
}

@keyframes rainbowFlash {
  0% {
    background-position: 0;
  }
  20% {
    background-position: 5%;
  }
  80% {
    background-position: 95%;
  }
  100% {
    background-position: 100%;
  }
}
