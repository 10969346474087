.radioButton {
  align-items: center;
  color: theme("colors.slate.900");

  input[type="radio"] {
    color: theme("colors.slate.900");
    appearance: none;
    display: inline-grid;
    place-content: center;
    background-color: white;
    border: 1px solid;
    border-color: theme("colors.slate.400");
    border-radius: 50%;
    width: 1rem;
    height: 1rem;
    font: inherit;

    &::before {
      box-shadow: inset 1em 1em theme("colors.slate.900");
      content: "";
      width: 0.4rem;
      height: 0.4rem;
      border-radius: 50%;
      transform: scale(0);
      transition: 120ms transform ease-in-out;
    }

    &:checked {
      border-color: theme("colors.slate.900");
      background: #ffffff;
      border: 0.15em solid;

      &::before {
        transform: scale(1);
      }
    }

    &:disabled {
      cursor: not-allowed;
      border-color: theme("colors.slate.400");
      background-color: theme("colors.surface.secondary");

      &::before {
        box-shadow: inset 1em 1em theme("colors.slate.400");
      }
    }
  }
}
