@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  /* Define some colours differently in light and dark modes */
  :root {
    --color-default: theme("colors.slate.50");
    --color-modal-bg: white;
    --color-cal-empty: rgba(255, 255, 255, 0.5);
    --color-icon-operational: #17ab2e;
    --color-icon-degraded: #d9bb22;
    --color-icon-partial-outage: #fb923c;
    --color-icon-full-outage: #e47964;
    --color-icon-maintenance: #57ace2;
  }

  .dark {
    --color-default: theme("colors.slate.800");
    --color-modal-bg: theme("colors.slate.900");
    --color-cal-empty: rgba(20, 23, 28, 0.5);
    --color-icon-operational: #50bf61;
    --color-icon-degraded: #dbc44b;
    --color-icon-partial-outage: #ff9946;
    --color-icon-full-outage: #f25533;
    --color-icon-maintenance: #438dbd;
  }
}

@layer utilities {
  /* Global */
  $bg-global: radial-gradient(
      75.93% 33.94% at 50% 0%,
      rgba(41, 51, 65, 0.3) 0%,
      rgba(41, 51, 65, 0) 100%
    ),
    #161618;
  .bg-global {
    background: $bg-global;
    background-attachment: fixed;
  }

  /* AI gradient */
  $bg-ai-rainbow: linear-gradient(
    92deg,
    #DADAF1 -1.6%,
    #E9E2EE 25.29%,
    #F1E1DF 44.5%,
    #EFE4D2 53.83%,
    #CFE3E8 70.84%,
    #C1B7DF 103.77%);

  .bg-ai-rainbow {
    background: $bg-ai-rainbow;
  }

  // Tailwind doesn't support breaking words the way we want it to.
  // This class means we'll default to not breaking words, and just use a new line instead.
  // In the case that we have a very long word/link that extends the max-width of its container,
  // We will break that word and wrap it to the next line.
  .break-words {
    overflow-wrap: anywhere;
    word-break: break-word;
  }
}
