@use "src/scss/globals" as *;

.mobileMenu {
  position: absolute;
  top: 1.5rem;
  right: 1rem;
  button {
    padding: 0.25rem !important;
  }
}
