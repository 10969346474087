.withBoxWrapper {
  .cell:first-child {
    padding-left: 16px;
  }
  .cell:last-child {
    padding-right: 16px;
  }
  .lastRow {
    .cell {
      border-bottom: 0px;

      &:first-child {
        border-bottom-left-radius: 8px;
      }
      &:last-child {
        border-bottom-right-radius: 8px;
      }
    }
  }
}
