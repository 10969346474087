@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,300;0,400;0,600;0,700;0,900;1,200;1,300;1,400;1,600;1,700;1,900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Roboto:300,300italic,500,500italic,700,700italic");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css?family=Lato:400,700");
@import url("https://fonts.googleapis.com/css?family=Source+Code+Pro:400");

@tailwind utilities;
@layer utilities {
  .flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .flex-align-start {
    display: flex;
    align-items: flex-start;
  }

  .flex-center-y {
    display: flex;
    align-items: center;
  }

  .break-anywhere {
    overflow-wrap: anywhere;
    word-break: break-word;
    hyphens: auto;
  }

  .inline-flex-center {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .flex-between {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .flex-column-center {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .two-column {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .two-column > * {
    flex-grow: 1;
    flex-basis: calc((30rem - 100%) * 999);
  }

  @media screen and (max-width: 640px) {
    .mobile-hidden {
      display: none;
    }
  }

  // Tailwind doesn't support breaking words the way we want it to.
  // This class means we'll default to not breaking words, and just use a new line instead.
  // In the case that we have a very long word/link that extends the max-width of its container,
  // We will break that word and wrap it to the next line.
  .break-words {
    overflow-wrap: anywhere;
    word-break: break-word;
  }

  .anchored {
    animation: noteblinker 1.5s linear;

    @keyframes noteblinker {
      0% {
        background-color: theme("colors.amber.200");
      }
    }
  }
}

// when in a drawer, we need to override the top and left styles that react-beautiful-dnd sets
// This comment: https://stackoverflow.com/questions/54982182/react-beautiful-dnd-drag-out-of-position-problem#comment122557541_67075990
.drawer-container [data-rbd-draggable-id] {
  top: auto !important;
  left: auto !important;
}

body {
  /* This makes all fonts render just a bit nicer in good browsers */
  @apply antialiased;
  // Our default text size across the app should be 14px
  @apply text-sm;
}

@tailwind base;
@layer base {
  :root {
    --chart-1: 234 85 69; // ea5545
    --chart-2: 39 174 239; // 27aeef
    --chart-3: 179 61 198; // b33dc6
    --chart-4: 244 106 155; // f46a9b
    --chart-5: 239 155 32; // ef9b20
    --chart-6: 237 191 51; // edbf33
    --chart-7: 237 225 91; // ede15b
    --chart-8: 189 207 50; // bdcf32
    --chart-9: 135 188 69; // 87bc45
  }
}
