@use "src/scss/globals" as *;

.headingExplanationWrapper {
  display: flex;
  justify-content: space-between;

  align-items: flex-end;

  @include mq($from: large) {
    align-items: center;
  }
}

.headingAccessory {
  min-width: 25%;
  text-align: left;
  margin-top: 1rem;
}
