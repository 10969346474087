.borderTransition {
  background: linear-gradient(
    160deg,
    rgba(132, 218, 131, 1) 0%,
    rgba(252, 165, 165, 1) 70%
  );
}

:global(.dark) {
  .borderTransition {
    background: linear-gradient(
      160deg,
      rgba(96, 255, 104, 0.26) 0%,
      rgba(242, 85, 51, 0.26) 70%
    );
  }
}
