@font-face {
  font-family: "sp-inter-variable";
  src: url(../../../../../../packages/status-page-ui/src/fonts/InterVariable.ttf)
    format(truetype);
}

var {
  font-style: inherit;
}

.interVariable {
  * {
    font-family:
      "sp-inter-variable",
      "Inter",
      -apple-system,
      BlinkMacSystemFont,
      "Segoe UI",
      Roboto Oxygen,
      Ubuntu,
      Cantarell,
      "Open Sans",
      "Helvetica Neue",
      sans-serif;
  }
}
