.custom-gradient-bg {
    background: linear-gradient(180deg, #FABBAE, #FABBAE00);
    background-blend-mode: overlay;
  }

.gradient-circle {
    position: absolute;
    border-radius: 50%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  .circle-1 {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.6), rgba(255, 255, 255, 0));
    width: 15vw;
    height: 15vw;
  }
  
  .circle-2 {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.4), rgba(255, 255, 255, 0));
    width: 20vw;
    height: 20vw;
  }
  
  .circle-3 {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    width: 25vw;
    height: 25vw;
  }
  
  .circle-4 {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.2), rgba(255, 255, 255, 0));
    width: 30vw;
    height: 30vw;
  }

  .circle-5 {
    background: radial-gradient(circle, rgba(255, 255, 255, 0.1), rgba(255, 255, 255, 0));
    width: 35vw;
    height: 35vw;
  }

  