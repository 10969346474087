@use "_variables.scss" as *;

// Drop this in your components to add a focus ring.
@mixin focus-ring($color) {
  html[data-whatintent="keyboard"] &:focus {
    @include box-shadow-ring($color);
  }
}

// Just the focus ring, without the :focus pseudoselector.
@mixin box-shadow-ring($color) {
  outline: 2px solid transparent;
  outline-offset: 2px;
  border-color: transparent;
  box-shadow: 0 0 0 2px $color;
}

// Use this to hide things from the UI, but make them
// visible to screenreaders.
@mixin visually-hidden {
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

// Truncates overly long text with ellipsis
@mixin truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

// Allows you to use all the sizes defined in variables
@mixin size($property) {
  @each $name, $size in $sizes {
    &-#{"" + $name} {
      #{$property}: $size;
    }
  }
}

$sizes: (
  xxs: $xxs,
  xs: $xs,
  s: $s,
  m: $m,
  l: $l,
  xl: $xl,
  xxl: $xxl,
);

// stolen from https://css-tricks.com/snippets/css/prevent-long-urls-from-breaking-out-of-container/
@mixin wrapurls() {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}
