@use "src/scss/globals" as *;

.header {
  display: flex;
  max-height: 4rem;
  align-items: center;
  justify-content: space-between;

  button:hover {
    background: none !important;
  }

  @include mq($from: medium) {
    display: none;
  }
}

.avatarPlaceholder {
  display: flex;
  width: 1.5rem;
  height: 1.5rem;
}

.notificationsButton {
  color: theme("colors.slate.600") !important;
}

.unreadNotificationsWrapper {
  margin-left: -14px;
  margin-top: -20px;
  height: 1.1rem;
  width: 1.1rem;
  z-index: 25;
}

.unreadNotifications {
  height: 100%;
  width: 100%;
  background-color: theme("colors.alarmalade.600");
  color: white;
  border-radius: 100%;
  font-size: 0.65rem;
  display: flex;
  justify-content: center;
  justify-self: center;
  align-items: center;
  z-index: 25;
}
