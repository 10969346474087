.withSelection {
  /* Remove the tap highlight on Android */
  -webkit-tap-highlight-color: transparent;

  /* And make selection pretty */
  ::selection {
    @apply text-content-primary;
    background-color: rgb(223 228 234) !important;
    background-clip: border-box !important;
    -webkit-text-fill-color: rgb(23 33 47) !important;
    -webkit-background-clip: border-box !important;
  }
}
