.activeCircle {
  border-radius: 50%;
  width: 8px;
  height: 8px;
  margin: 3px;
  flex-shrink: 0;

  &.animatedActiveCircle {
    animation: pulse 3s linear;
    animation-iteration-count: infinite;
    @keyframes pulse {
      0% {
        background: theme("colors.alarmalade.600");
      }
      25% {
        background: theme("colors.alarmalade.600");
      }
      50% {
        background: theme("colors.red.300");
      }
      75% {
        background: theme("colors.alarmalade.600");
      }
      100% {
        background: theme("colors.alarmalade.600");
      }
    }
  }

  &:not(.animatedActiveCircle) {
    background: theme("colors.alarmalade.600");
  }
}
