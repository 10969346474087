@use "src/scss/globals" as *;

.followUpTable {
  td,
  th {
    padding: 0.25rem 0.75rem;
    div {
      @include mq($until: mobile) {
        flex-wrap: wrap;
        justify-content: center;
      }
    }
  }
}

.followUpStatistics {
  td,
  th {
    padding: 0.75rem;
  }
}

.makeInputTextSizeCorrect {
  input {
    font-size: 0.875rem !important;
  }
}

.description {
  @include wrapurls();
}

.statisticsDropdown {
  min-width: 225px;
}
