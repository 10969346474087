.base {
  max-width: 100%;
  overflow-wrap: anywhere;

  code {
    white-space: pre-wrap;
  }

  ol {
    list-style: decimal inside;
  }

  ul {
    list-style: circle inside;
    list-style-type: disc;
  }

  li {
    margin-left: 1rem;
  }

  p {
    margin-bottom: 0 !important;
  }

  a {
    @apply underline;
  }

  h1 {
    @apply text-xl;
    @apply font-bold;
  }
  h2 {
    @apply text-lg;
    @apply font-bold;
  }
  h3 {
    @apply font-bold;
  }
}

.styled {
  a {
    @apply text-content-primary;
  }
}
