@use "src/scss/globals" as *;

@mixin templated-text-content {
  h1,
  h2,
  h3,
  p,
  ul,
  ol {
    &:not(:last-child) {
      margin-bottom: 10px !important;
    }
  }

  h1 {
    @apply pt-4;
    @apply text-xl;
    @apply font-semibold;
  }

  h2 {
    @apply pt-4;
    @apply text-lg;
    @apply font-semibold;
  }

  h3 {
    @apply pt-4;
    @apply font-semibold;
  }

  p {
    @apply m-0;
  }

  ul,
  ol {
    margin-left: 1.6rem;
  }

  ul li {
    @apply list-disc;
  }

  ol li {
    @apply list-decimal;
  }

  blockquote {
    @apply border-l-4;
    padding-left: $s;
  }

  blockquote,
  pre {
    margin-bottom: $s;
  }

  /* Inline code */
  p code {
    @apply bg-surface-secondary;
    @apply border;
    @apply border-stroke;
    @apply px-1;
    @apply rounded;
  }

  /* Code block */
  pre {
    @apply bg-surface-secondary;
    @apply border;
    @apply border-stroke;
    @apply px-1;
    @apply rounded;
  }
}
