@use "src/scss/globals" as *;

.muteSection {
  visibility: hidden; /* hide element at the start */
}

.notificationTitle,
.notificationContent {
  b {
    font-weight: 600 !important;
  }
}

.notificationContent {
  margin-left: 1px;
}

.notificationWrapper {
  &:hover {
    .muteSection {
      visibility: visible;
    }
  }
}
