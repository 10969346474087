@use "src/scss/globals" as *;

@use "_mixins.scss" as *;

.templatedTextContent {
  @include templated-text-content;

  tr,
  td {
    border: black 1px solid;
  }

  hr {
    margin: 16px 0;
  }

  a {
    @apply text-content-primary;
    @apply underline;
  }
}

.truncateContent {
  display: -webkit-box;
  overflow: hidden;
  -webkit-line-clamp: 10;
  -webkit-box-orient: vertical;
}
