@use "src/scss/globals" as *;

.assigneeName,
.statusText {
  display: none;
  @include mq($from: medium) {
    display: inline;
  }
}

.descriptionCol {
  @include mq($until: large) {
    word-wrap: break-word;
  }
}

.ownerCol {
  width: 20%;
}

.statusCol {
  width: 15%;
  white-space: nowrap;
}

.assigneeCell,
.statusCell {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: clip;

  img {
    margin: 0;
  }

  @include mq($from: small) {
    justify-content: flex-start;
    img {
      margin-right: 0.5rem;
    }
  }
}

.table {
  width: 100%;
  table {
    text-align: center;
    @include mq($from: small) {
      text-align: left;
    }
  }
}

.externalIssueColumn {
  width: 20%;
  @include mq($until: small) {
    display: none;
  }
}

.menuCell {
  width: 1rem;
}

.anchored {
  animation: blinker 1.5s linear;

  @keyframes blinker {
    50% {
      background-color: theme("colors.amber.200");
    }
  }
}

.rainbowBackground {
  border-color: theme("colors.purple.100");
  background-image: linear-gradient(
    36deg,
    rgba(255, 255, 255, 0.26) 34.63%,
    rgba(233, 213, 255, 0.26) 58.92%,
    rgba(191, 219, 254, 0.26) 80.51%,
    rgba(250, 187, 174, 0.26) 101.77%
  );
}

.rainbowSuggestionBackground:hover {
  background-image: linear-gradient(
    92deg,
    rgba(218, 218, 241, 0.3) -1.6%,
    rgba(233, 226, 238, 0.3) 25.29%,
    rgba(241, 225, 223, 0.3) 44.5%,
    rgba(239, 228, 210, 0.3) 53.83%,
    rgba(207, 227, 232, 0.3) 70.84%,
    rgba(193, 183, 223, 0.3) 103.77%
  );
}
