$light-border: theme("colors.slate.100");
$dark-border: theme("colors.slate.700");

.default {
  // This background acts as a border
  @apply bg-slate-100;

  :global(.dark) & {
    background: $dark-border;
  }

  // And the real background sits inside
  .header {
    background: var(--color-default);
  }
}

.operational {
  // This background acts as a border
  background: linear-gradient(
    170deg,
    rgba(120, 206, 119, 1) 0%,
    $light-border 80%
  );

  :global(.dark) & {
    background: linear-gradient(
      170deg,
      rgba(80, 191, 97, 0.7) 0%,
      $dark-border 80%
    );
  }

  // And the real background sits inside
  .header {
    background: linear-gradient(
      160deg,
      rgba(76, 172, 91, 0.2) 0%,
      var(--color-default) 100%
    );
  }
}

.degradedPerformance {
  // This background acts as a border
  background: linear-gradient(
    125deg,
    rgba(228, 202, 74, 1) 0%,
    $light-border 80%
  );

  :global(.dark) & {
    background: linear-gradient(
      125deg,
      rgba(219, 196, 75, 0.66) 0%,
      $dark-border 80%
    );
  }

  // And the real background sits inside
  .header {
    background: linear-gradient(
      160deg,
      rgba(239, 209, 61, 0.15) 0,
      var(--color-default) 65%
    );
  }

  :global(.dark) & {
    .header {
      background: linear-gradient(
        160deg,
        rgba(219, 196, 75, 0.2) 0,
        var(--color-default) 65%
      );
    }
  }
}

.partialOutage {
  // This background acts as a border
  background: linear-gradient(
    125deg,
    rgba(232, 175, 129, 1) 0%,
    $light-border 50%
  );

  :global(.dark) & {
    background: linear-gradient(
      125deg,
      rgba(254, 126, 21, 0.66) 0%,
      $dark-border 50%
    );
  }

  // And the real background sits inside
  .header {
    background: linear-gradient(
      160deg,
      rgba(255, 146, 57, 0.15) 0,
      var(--color-default) 65%
    );
  }

  :global(.dark) & {
    .header {
      background: linear-gradient(
        160deg,
        rgba(237, 133, 47, 0.2) 0,
        --color-default 65%
      );
    }
  }
}

.fullOutage {
  // This background acts as a border
  background: linear-gradient(
    125deg,
    rgba(238, 154, 138, 1) 0%,
    $light-border 50%
  );

  :global(.dark) & {
    background: linear-gradient(
      125deg,
      rgba(242, 85, 51, 0.66) 0%,
      $dark-border 50%
    );
  }

  // And the real background sits inside
  .header {
    background: linear-gradient(
      160deg,
      rgba(228, 121, 100, 0.15) 0%,
      var(--color-default) 65%
    );
  }

  :global(.dark) & {
    .header {
      background: linear-gradient(
        160deg,
        rgba(242, 85, 51, 0.2) 0%,
        rgba(242, 85, 51, 0.1) 35%,
        var(--color-default) 65%
      );
    }
  }
}

.underMaintenance {
  // This background acts as a border
  background: linear-gradient(
    125deg,
    rgba(121, 184, 224, 1) 0%,
    $light-border 50%
  );

  :global(.dark) & {
    background: linear-gradient(
      125deg,
      rgba(64, 157, 218, 0.66) 0%,
      $dark-border 50%
    );
  }

  // And the real background sits inside
  .header {
    background: linear-gradient(
      160deg,
      rgba(87, 172, 226, 0.15) 0,
      rgba(245, 247, 249, 1) 65%
    );
  }
  :global(.dark) & {
    .header {
      background: linear-gradient(
        160deg,
        rgba(47, 159, 232, 0.2) 0,
        var(--color-default) 65%
      );
    }
  }
}
