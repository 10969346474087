.table {
  @apply rounded-2;
  @apply divide-y;
  @apply divide-stroke;

  > :first-child {
    @apply rounded-t-lg;
  }

  > :last-child {
    @apply rounded-b-lg;
  }
}
