.loadingGradient {
  animation-duration: 4s;
  animation-fill-mode: backwards;
  animation-iteration-count: infinite;
  animation-name: Shimmer;
  animation-timing-function: linear;
  background-size: 400% 400%;
  background-attachment: fixed;

  position: relative;

  --color-base: theme("colors.slate.200 / 33%");
  --color-highlight: theme("colors.slate.300 / 27%");

  :global(.dark) & {
    --color-base: theme("colors.slate.800 / 50%");
    --color-highlight: theme("colors.slate.700 / 33%");
  }

  background-color: var(--color-base);
  background-image: linear-gradient(
    270deg,
    var(--color-base) 33%,
    var(--color-highlight) 50%,
    var(--color-base) 66%
  );
}

@keyframes Shimmer {
  0% {
    background-position: -200% 0%;
  }
  100% {
    background-position: 200% 0%;
  }
}
