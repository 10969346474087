.default,
.modal {
  // General stuff
  @apply transition;
  @apply font-medium;
  border-radius: 8px;

  // Lightmode
  @apply bg-surface-tertiary/40;
  @apply text-content-primary;
  &:hover {
    @apply bg-surface-tertiary/70;
  }
}

.invisible {
  @apply text-slate-500;

  &:hover {
    @apply text-content-primary;
  }
}

:global(.dark) {
  .default,
  .modal {
    @apply bg-surface-invert/50;
    @apply text-slate-50;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    &:hover {
      @apply bg-surface-invert;
    }
  }

  // In modals, the background should not be transparent
  .modal {
    @apply bg-surface-invert;

    &:hover {
      @apply bg-slate-600/50;
    }
  }

  .invisible {
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    @apply text-content-tertiary;

    &:hover {
      @apply text-slate-200;
    }
  }
}
