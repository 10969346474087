.degradedPerformance {
  @apply bg-cal-degraded;
  background: rgba(239, 209, 61, 0.21);

  :global(.dark) & {
      background: rgba(219, 196, 75, 0.17);
  }

  &.highlighted, &:hover {
    border-color: rgba(219, 196, 75, 0.6);
  }
}

.partialOutage {
  @apply bg-cal-partial-outage;

  :global(.dark) & {
    background: rgba(207, 115, 40, 0.17);
  }

  &.highlighted, &:hover {
    border-color: rgba(234, 146, 75, 0.6);
  }
}

.fullOutage {
  @apply bg-cal-full-outage;

  background: rgba(242, 85, 51, 0.17);
  :global(.dark) & {
    background: rgba(228, 121, 100, 0.14);
  }

  &.highlighted, &:hover {
    border-color: rgba(242, 85, 51, 0.6);
  }
}

.underMaintenance {
  @apply bg-cal-maintenance;

  background: rgba(220, 238, 249, 1);

  :global(.dark) & {
    background: rgba(47, 159, 232, 0.2);
  }
  &.highlighted, &:hover {
    border-color: rgba(47, 159, 232, 0.6);
  }
}
