.AIrainbow {
  background: linear-gradient(
      36deg,
      rgba(255, 255, 255, 0.26) 34.63%,
      rgba(233, 213, 255, 0.26) 58.92%,
      rgba(191, 219, 254, 0.26) 80.51%,
      rgba(250, 187, 174, 0.26) 101.77%
    ),
    var(--Color-Surface-Secondary, #f7f7f8);
}
