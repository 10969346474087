.checkbox {
  display: inline-flex;
  align-items: center;

  input[type="checkbox"] {
    border-radius: 4px;

    @apply bg-white;

    // The ring effect is really annoying
    &:focus {
      @apply focus:ring-0 focus:ring-offset-0;
    }

    // Lightmode settings
    @apply text-content-primary;
    @apply border-stroke;

    &:focus, &:hover {
      @apply border-slate-500;
      @apply bg-transparent;
    }

    // We are _very fancy_ and use a custom tick SVG that's even nicer than Tailwind's
    &:checked {
      background-image: url("data:image/svg+xml,%3Csvg viewBox='-2.25 -2.25 14.5 12.5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.40387 4.63462L3.30772 6.75L8.59618 1.25' stroke='%2317212f' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
    }
  }
}

:global(.dark) {
  .checkbox {
    input[type="checkbox"] {
      @apply bg-transparent;
      @apply text-slate-50;
      @apply border-slate-400;

      &:focus, &:hover {
        @apply border-slate-50;
      }

      // We also need to customise this here too, since the stroke colour is
      // built in to the SVG.
      &:checked {
        background-image: url("data:image/svg+xml,%3Csvg viewBox='-2.25 -2.25 14.5 12.5' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1.40387 4.63462L3.30772 6.75L8.59618 1.25' stroke='%23f5f7f9' stroke-width='1.5' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
      }
    }
  }
}
