@use "src/scss/globals" as *;

.navLinkTooltip {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-width: 0;

  @include mq($until: medium) {
    display: none;
  }
  @include mq($from: wide) {
    display: none;
  }
}
