@use "variables" as *;
@forward "utils";

/* This sets up tailwind for us */
@import "../../../../packages/status-page-ui/src/global.scss";

/*
We never want our <input> elements to have the outline that is applied
when they have focus: we only ever use our own form components, which handle
focus states in wrappers around the element
*/
input {
  &:focus {
    box-shadow: none !important;
    outline: none;
  }
}

* {
  font-family:
    "Inter",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    Roboto,
    Oxygen,
    Ubuntu,
    Cantarell,
    "Open Sans",
    "Helvetica Neue",
    sans-serif;

  font-variant-numeric: tabular-nums;
}

// We need to use this class rather than tailwind's `font-mono` because the above selector explicitly sets the font-family
// on all elements, which means children do not inherit the font-family from their parents. This saves us from having to
// explicitly set the font-family on every element that we want to use the monospace font.
.mono * {
  font-family: "Source Code Pro", ui-monospace, SFMono-Regular, Menlo, Monaco,
    Consolas, "Liberation Mono", "Courier New", monospace;
}

#root {
  height: 100vh;
}

body {
  background-color: theme("colors.slate.800");

  color: theme("colors.slate.900");

  &[data-noscroll] {
    overflow: hidden;
  }
}

[data-blur] {
  filter: url(#blur);
}

hr {
  color: theme("colors.slate.200");
}

// We want to override the cursor to be the default pointer everywhere in the entire
// dashboard whereever we use react flow.
div.react-flow__pane {
  cursor: default;
}
