.accordionContainer {
  .chevron {
    transform: rotate(90deg) !important;
  }

  [data-state="open"] {
    .chevron {
      transform: rotate(180deg) !important;
    }
  }
}
