// Calendar container
.container {
  background-color: theme("colors.surface.secondary");
  :global(.dark) & {
    background-color: theme("colors.slate.800");
  }

  @apply grid;
  @apply grid-cols-7;
  @apply gap-px;
  @apply text-sm;
  border-radius: 7px;
}

// Today highlight
.today {
  // The grid is provided by a 1px gap around each day, which shows the
  // `.container` background underneath. For today, we want to undo that gap, so
  // that our border replaces it.
  margin: -1px;
  @apply border;
  @apply border-slate-300;

  :global(.dark) & {
    @apply border-slate-700;
  }
}

// Status-based day styles
.operational {
  @apply text-content-tertiary;
  @apply bg-surface-secondary/50;
  @apply border-stroke/50;

  :global(.group):hover & {
    @apply text-slate-600;
    @apply border-stroke;
  }

  :global(.dark) & {
    @apply text-slate-500;
    @apply border-slate-500;
    @apply bg-transparent;
  }

  :global(.dark) :global(.group):hover & {
    @apply text-content-tertiary;
    @apply border-slate-300;
  }
}

.underMaintenance {
  @apply text-cal-maintenance-bold;
  @apply border-cal-maintenance;
  @apply bg-cal-maintenance;

  :global(.group):hover & {
    border-color: rgba(47, 159, 232, 0.6);
  }

  :global(.dark) & {
    @apply text-cal-maintenance-dark;
    @apply border-cal-maintenance-dark;
    @apply bg-transparent;
  }

  :global(.dark) :global(.group):hover & {
    @apply text-cal-maintenance-hover;
    @apply border-cal-maintenance-hover;
  }
}

.degradedPerformance {
  color: theme("colors.cal-degraded.bold");
  border-color: theme("colors.cal-degraded.DEFAULT");
  background-color: theme("colors.cal-degraded.DEFAULT");

  :global(.group):hover & {
    border-color: rgba(219, 196, 75, 0.6);
  }

  :global(.dark) & {
    @apply text-cal-degraded-dark;
    @apply border-cal-degraded-dark;
    @apply bg-transparent;
  }

  :global(.dark) :global(.group):hover & {
    @apply text-cal-degraded-hover;
    @apply border-cal-degraded-hover;
  }
}

.partialOutage {
  @apply text-cal-partial-outage-bold;
  @apply border-cal-partial-outage;
  @apply bg-cal-partial-outage;

  :global(.group):hover & {
    border-color: rgba(234, 146, 75, 0.6);
  }

  :global(.dark) & {
    @apply text-cal-partial-outage-dark;
    @apply border-cal-partial-outage-dark;
    @apply bg-transparent;
  }

  :global(.dark) :global(.group):hover & {
    @apply text-cal-partial-outage-hover;
    @apply border-cal-partial-outage-hover;
  }
}

.fullOutage {
  @apply text-cal-full-outage-bold;
  @apply border-cal-full-outage;
  @apply bg-cal-full-outage;

  :global(.group):hover & {
    border-color: rgba(242, 85, 51, 0.6);
  }

  :global(.dark) & {
    @apply text-cal-full-outage-dark;
    @apply border-cal-full-outage-dark;
    @apply bg-transparent;
  }

  :global(.dark) :global(.group):hover & {
    border-color: rgba(242, 85, 51, 0.6);
    @apply text-cal-full-outage-hover;
    @apply border-cal-full-outage-hover;
  }
}
