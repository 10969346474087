@use "src/scss/globals" as *;

.stackedList {
  border-radius: 8px;
  list-style: none;
  background: white;

  & > :first-child {
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
  }
  & > :last-child {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
  }

  // This will apply to every immediate child element
  > * {
    border: 1px solid theme("colors.stroke.DEFAULT");
  }

  > *[data-no-separator] {
    border-bottom-width: 0;
    & + * {
      border-top-width: 0;
    }
  }

  & > :not(:last-child) {
    border-bottom: 0;
  }

  &.noSeparator {
    & > * {
      border-top: 0;
    }
  }
}
