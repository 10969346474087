.root {
  [cmdk-root] {
    overflow-y: expand;
  }

  [cmdk-list] {
    overflow: auto;
    overflow-y: expand;
    overscroll-behavior: contain;
    transition: 100ms ease;
    transition-property: height;

    min-height: 150px;
  }

  [cmdk-item] {
    content-visibility: auto;
    user-select: none;
    will-change: background, color;

    &[aria-selected="true"] {
      @apply bg-surface-secondary border-stroke;
    }

    &:active {
      transition-property: background;
      @apply bg-surface-secondary;
    }
  }
}
