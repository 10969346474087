@use "src/scss/globals" as *;

// This is the modal box
.dialogContent {
  position: relative;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  @apply rounded-2;
  text-align: left;
  overflow: hidden;
  box-shadow:
    0px 6px 79px rgba(14, 14, 30, 0.1),
    0px 1px 2px rgba(10, 20, 33, 0.1),
    0px 6px 24px rgba(10, 20, 33, 0.16);
  z-index: 100;
  @include mq($until: mobile) {
    min-width: 100vw;
    max-width: 100vw;
  }

  @include mq($from: mobile, $until: tablet) {
    min-width: 80vw;
    max-width: 80vw;

    &.xxl {
      max-width: 100vw;
      width: 100vw;
    }
  }

  @include mq($from: tablet) {
    max-height: fit-content !important;
    margin: 5vh auto;
    max-width: 32rem;
    width: 100%;

    &.xl {
      max-width: 60vw;
      width: 60vw;
    }

    &.xxl {
      max-width: 100vw;
      width: 100vw;
    }

    &.maximised {
      max-width: 90vw;
      width: 90vw;
    }
  }

  @include mq($from: large) {
    &.xxl {
      max-width: 90vw;
      width: 90vw;
    }
  }

  @include mq($from: xl) {
    &.xl {
      max-width: 50rem;
      @apply rounded-4;
    }

    &.xxl {
      max-width: 70rem;
      @apply rounded-4;
    }

    &.maximised {
      max-width: 70rem;
    }
  }
}

.closeButton {
  border-radius: 9999px;
  justify-content: center;
  svg {
    margin: 0 !important;
  }

  @include focus-ring(theme("colors.slate.900"));

  .closeText {
    @include visually-hidden;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.modalContent {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  text-align: left;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}
