@use "src/scss/globals" as *;

.viewport {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.8rem;
  width: 24rem;
  max-width: 100vw;
  margin: 0 auto;
  list-style: none;
  z-index: 999;

  @include mq($from: large) {
    margin: 0;
    right: 0;
  }
}

.toast {
  display: flex;
  justify-content: space-between;

  @media (prefers-reduced-motion: no-preference) {
    &[data-state="open"] {
      animation: slide-in 150ms cubic-bezier(0.16, 1, 0.3, 1);
    }
    &[data-state="closed"] {
      animation: hide 100ms ease-in forwards;
    }
    &[data-swipe="move"] {
      transform: translateX(var(--radix-toast-swipe-move-x));
    }
    &[data-swipe="cancel"] {
      transform: translateX(0);
      transition: transform 200ms ease-out;
    }
    &[data-swipe="end"] {
      animation: swipe-out 100ms ease-out forwards;
    }
  }
}

@keyframes hide {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes slide-in {
  from {
    transform: translateX(calc(100% + 1rem));
  }
  to {
    transform: translateX(0);
  }
}

@keyframes swipe-out {
  from {
    transform: translateX(var(--radix-toast-swipe-end-x));
  }
  to {
    transform: translateX(calc(100% + 1rem));
  }
}
