@use "src/scss/globals" as *;

.toggle {
  cursor: pointer;
  width: 1.8rem;
  display: flex;
  position: relative;
  border-radius: 999rem;
  align-items: center;

  &.disabled {
    cursor: not-allowed;
    @apply opacity-50;
  }

  html[data-whatintent="keyboard"] &:focus-within {
    @include box-shadow-ring(theme("colors.slate.600"));
    input {
      outline: none;
    }
  }

  input {
    opacity: 0;
    &:checked + .slider {
      transform: translateX(0.7rem);
    }
  }

  .slider {
    position: absolute;
    border-radius: 999px;
    transition: transform 200ms ease-in-out;
    height: 0.8rem;
    width: 0.8rem;
    min-width: 0.8rem;
    left: 2px;
    transform: translateX(0);
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.04);
  }
}
