.triggerButton {
  [data-state="open"] & {
    svg {
      transform: rotate(90deg);
    }
  }
}

.accordionContent {
  overflow: hidden;
  &[data-state="open"] {
    animation: open 0.2s ease-out;
  }
  &[data-state="closed"] {
    animation: close 0.2s ease-out;
  }
}

@keyframes open {
  from {
    height: 0;
  }
  to {
    height: var(--radix-accordion-content-height);
  }
}

@keyframes close {
  from {
    height: var(--radix-accordion-content-height);
  }
  to {
    height: 0;
  }
}
