@use "src/scss/globals" as *;

.form {
  /*
   * Inside a non-modal form, we want text to not get too wide: very long lines
   * are hard for humans to read
   */
  .readableText {
    max-width: theme("maxWidth.xl");
  }
}
