@use "_mixins.scss" as *;

.multiLine {
  :global {
    .ProseMirror {
      min-height: 4rem !important;
    }
  }
}

.templatedTextEditorContent {
  @include templated-text-content;

  a {
    @apply text-content-primary;
    @apply underline;

    // This keeps a link highlighted when it is being edited
    &[data-editing="true"] {
      @apply bg-surface-tertiary;
      text-decoration: none !important;
    }
  }

  li p {
    display: inline;
  }

  table {
    @apply border-collapse my-1;
  }

  table:not(:last-child) {
    margin-bottom: 14px !important;
  }

  table th,
  table td {
    @apply border border-stroke p-2;
    max-width: 420px;
    min-width: 40px;
    word-wrap: break-word;
  }

  table th {
    @apply bg-surface-tertiary;
  }

  table tr:nth-child(even) {
    @apply bg-surface-secondary;
  }
}

.toolbar {
  font-family: Inter, sans-serif;
}

.ProseMirror-selectednode {
  @apply border-2 border-blue-300;
}

.placeholder {
  @apply text-content-tertiary;
}
