.background {
  background-image: linear-gradient(
    66deg,
    #f25533 40%,
    #f1cd98 90%,
    #f8f5ef 100%
  );
}

.staticTextGradient {
  background: linear-gradient(66deg, #f25533 40%, #f1cd98 90%, #f8f5ef 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.textGradient {
  // Single gradient on parent only
  background-image: linear-gradient(
    to bottom,
    theme("colors.content.primary") 0%,
    theme("colors.content.primary") 25%,
    #f25533 40%,
    #f59e0b 60%,
    theme("colors.content.primary") 75%,
    theme("colors.content.primary") 100%
  );
  background-size: 100% 400%;
  background-position: 0 -150%;
  animation: gradientSweep 3s ease-out forwards;

  // Clip background to text
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;

  & * {
    color: theme("colors.content.primary");
    // Make descendants transparent to reveal parent gradients
    -webkit-text-fill-color: transparent;
  }

  // For the TemplatedTextDisplay use case, lets make this gradient+background
  // CSS work well with it.
  & :global(.prosemirror-editor) > :global(.ProseMirror) {
    position: static !important;
    li {
      position: static !important;
    }
  }
}

@keyframes gradientSweep {
  from {
    background-position: 0 -150%;
  }
  to {
    background-position: 0 0;
  }
}

.upsellBackground {
  background-image: linear-gradient(
    180deg,
    rgba(254, 239, 236, 1) 0%,
    rgba(241, 205, 152, 0.3) 50%,
    rgba(241, 205, 152, 0) 100%
  );
}

.backgroundGradient {
  background-image: linear-gradient(
    45deg,
    theme("colors.surface.primary") 0%,
    theme("colors.surface.primary") 20%,
    theme("colors.amber.50") 40%,
    theme("colors.alarmalade.50") 60%,
    theme("colors.surface.primary") 80%,
    theme("colors.surface.primary") 100%
  );
  background-size: 500% 500%;
  animation: backgroundGradientSweep 3s ease-in-out forwards;
}

@keyframes backgroundGradientSweep {
  from {
    background-position: 0 100%;
  }
  to {
    background-position: 100% 0;
  }
}

.textGradientAngled {
  background-image: linear-gradient(
    45deg,
    theme("colors.content.primary") 0%,
    theme("colors.content.primary") 25%,
    #f25533 40%,
    #f59e0b 60%,
    theme("colors.content.primary") 75%,
    theme("colors.content.primary") 100%
  );
  background-size: 500% 500%;
  animation: gradientAngledSweep 3s ease-out forwards;

  // Clip background to text
  -webkit-background-clip: text;
  background-clip: text;
  color: transparent;
}

@keyframes gradientAngledSweep {
  from {
    background-position: 0 100%;
  }
  to {
    background-position: 100% 0;
  }
}
