@use "src/scss/globals" as *;

.assigneeName,
.statusText {
  display: none;
  @include mq($from: medium) {
    display: inline;
  }
}

.descriptionCol {
  @include mq($until: large) {
    word-wrap: break-word;
  }
}

.ownerCol {
  width: 20%;
}

.statusCol {
  width: 15%;
  white-space: nowrap;
}

.assigneeCell,
.statusCell {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow-x: clip;

  img {
    margin: 0;
  }

  @include mq($from: small) {
    justify-content: flex-start;
    img {
      margin-right: 0.5rem;
    }
  }
}

.table {
  width: 100%;
  table {
    text-align: center;
    @include mq($from: small) {
      text-align: left;
    }
  }
}

.externalIssueColumn {
  width: 20%;
  @include mq($until: small) {
    display: none;
  }
}

.externalIssueButton {
  @include mq($until: small) {
    display: none;
  }
}

.externalIssueMobileButton {
  margin-top: 2px;
  @include mq($from: small) {
    display: none;
  }
}
.menuCell {
  width: 1rem;
}

.anchored {
  animation: blinker 1.5s linear;

  @keyframes blinker {
    50% {
      background-color: theme("colors.amber.200");
    }
  }
}
