@use "src/scss/globals" as *;
.highlight {
  animation: blinker 1s linear;

  @keyframes blinker {
    0% {
      border-color: theme("colors.blue.400");
    }
  }
}

.highlightBarBackground {
  animation: blinkerBarBg 1s linear;

  @keyframes blinkerBarBg {
    0% {
      background-color: theme("colors.blue.100");
    }
  }
}

.highlightBackground {
  animation: blinkerBg 1s linear;

  @keyframes blinkerBg {
    0% {
      background-color: theme("colors.amber.100");
    }
  }
}

.cardContainer {
  background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 50%,
      rgba(228, 228, 231, 0.12) 100%
    ),
    white;

  &:hover {
    .nameSection {
      transform: translateY(calc(100% - 216px));
    }
  }
}
