@use "src/scss/globals" as *;

// Styles for Markdown responses from OpenAI model.
.query {
  // Paragraph.
  p {
    margin-bottom: 0 !important;
    @apply pb-3 leading-6;
  }
  p:last-child {
    @apply pb-0;
  }
  li > p {
    @apply leading-5;
  }

  // Ordered and unordered list.
  ol,
  ul {
    @apply ml-6 mb-3;
  }
  ol {
    @apply list-decimal;
  }
  ul {
    @apply list-disc;
  }

  // List item.
  li {
    @apply mb-2; // Space between list items.

    // Paragraphs within list items.
    p {
      @apply mb-0 pb-0; // Reduced space below paragraphs inside list items.
    }
  }
  li:last-child {
    @apply mb-0; // Space between list items.
  }

  // Specific styling for nested lists.
  li > ul,
  li > ol {
    @apply mt-1 mb-0; // Space above, and no extra space below.
  }

  // Bold items
  strong,
  b {
    @apply font-semibold;
  }

  // Preformatted blocks (such as code snippets).
  pre {
    @apply bg-slate-600 text-white rounded-0.5 p-4 overflow-auto;
  }

  // Preformatted blocks (such as code snippets).
  table {
    @apply overflow-x-auto whitespace-nowrap block border-0;
    tbody {
      @apply table w-full;
    }
  }
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}
.animatePulse {
  animation: pulse 3s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}
