@use "src/scss/globals" as *;

.incidentListItem {
  list-style: none;
  display: flex;
  flex-direction: row;

  > a {
    &:hover {
      text-decoration: none !important;
    }
    max-width: 100%;
    font-weight: normal;
    display: flex;
    flex-direction: column;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 0.75rem;

    @include mq($from: medium) {
      justify-content: space-between;
      align-items: center;
      flex-direction: row;
    }

    .info {
      width: 100%;
      min-width: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      line-clamp: 1;
      -webkit-line-clamp: 1;
    }
    .chevron {
      // only visible on wider aspects
      @include mq($from: medium) {
        display: flex;
      }
      display: none;
      justify-content: flex-end;
      align-items: center;
      flex-shrink: 1;
    }

    .lead {
      display: flex;
      justify-content: flex-end;
      flex-grow: 1;
    }
    .userRole {
      display: flex;
      justify-content: flex-start;
    }

    .badges {
      display: flex;
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 0.5rem;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
    }

    .customDisplayedInfo {
      display: flex;
      width: fit-content;
      margin-right: auto;
      flex-direction: row;
      align-items: flex-start;
      gap: 1rem;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-items: center;
      @apply bg-surface-secondary rounded-2 px-3 py-2 mt-2 border-stroke border;
    }
  }
}
