.wrap-urls {
  /* These are technically the same, but use both */
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  /* This is the dangerous one in WebKit, as it breaks things wherever */
  word-break: break-all;
  /* Instead use this non-standard one: */
  word-break: break-word;

  /* Adds a hyphen where the word breaks, if supported (No Blink) */
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

// This isn't supported by many browsers yet, or Tailwind, but it makes 'inline editing' much better
// so we use it when we're supporting inline editing.
.field-size-content {
  input {
    field-sizing: content;
  }
}
