@use "src/scss/globals" as *;

.tableWrapper {
  @include mq($until: medium) {
    border-left: 0;
    border-right: 0;
  }
  @include mq($from: medium) {
    border-bottom-left-radius: 0.5rem;
    border-bottom-right-radius: 0.5rem;
  }
}

.tableWrapperRoundedTop {
  @include mq($from: medium) {
    border-radius: 0.5rem;
  }
}

.table {
  border-spacing: 0;
  width: 100%;

  td,
  th {
    padding: 0.5rem;
  }

  th {
    border-color: theme("colors.stroke.DEFAULT");
    border-bottom-width: 1px;
  }

  tbody tr:not(:last-child) {
    border-bottom: 1px solid theme("colors.stroke.DEFAULT");
  }

  @include mq($from: medium) {
    tbody tr:not(:last-child) {
      border: solid theme("colors.stroke.DEFAULT");
      border-width: 1px 0px;
    }

    td,
    th {
      padding: 0.75rem;
    }
  }
}

.tableRoundedTop {
  @include mq($from: medium) {
    thead,
    thead tr {
      border-top-left-radius: 0.5rem;
      border-top-right-radius: 0.5rem;
    }

    th {
      &:first-child {
        border-top-left-radius: 0.5rem;
      }

      &:last-child,
      &.lastVisible {
        border-top-right-radius: 0.5rem;
      }
    }
  }
}
