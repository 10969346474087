.checkbox {
  input[type="checkbox"] {
    border-radius: 4px;

    &:checked {
      @apply border-slate-900;
      @apply text-content-primary;
    }
    &:disabled {
      cursor: not-allowed;

      background-color: theme("colors.surface.secondary");
      border-color: theme("colors.stroke.DEFAULT");

      &:checked {
        background-color: theme("colors.slate.500");
        border-color: theme("colors.slate.500");
      }
    }
  }
}
