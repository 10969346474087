.loadingGradient {
  animation-duration: 1.6s;
  animation-fill-mode: forwards;
  animation-iteration-count: infinite;
  animation-name: Shimmer;
  animation-timing-function: linear;
  background: theme("colors.blue.200");
  background: linear-gradient(
    to right,
    theme("colors.blue.200") 0%,
    theme("colors.blue.300") 50%,
    theme("colors.blue.200") 100%
  );
  background-size: 1000px 1000px;
  background-attachment: fixed;

  position: relative;
}

@keyframes Shimmer {
  0% {
    background-position: -500px 0px;
  }
  100% {
    background-position: 500px 0px;
  }
}
