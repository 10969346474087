@use "src/scss/globals" as *;

.filterForm {
  display: flex;
  flex-direction: column;

  & > fieldset {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.submenuContent {
  padding: 0 !important;
  min-width: 20rem;
}

.filtersList {
  display: flex;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.searchBarInput {
  margin-top: 0;
  height: 2.5rem;
}

.compactAppliedFilters {
  @include mq($until: tablet) {
    display: block;
  }
  @include mq($from: tablet) {
    display: none;
  }
}

.normalAppliedFilters {
  @include mq($until: tablet) {
    display: none;
  }
  @include mq($from: tablet) {
    display: flex;
  }
}
