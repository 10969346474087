.json {
  color: theme("colors.slate.600");

  * {
    @apply font-mono;
    @apply text-xs;
  }

  // JSON Keys and comments
  :global(.hljs-attr),
  :global(.hljs-comment) {
    color: theme("colors.slate.700");
  }

  // JSON Values
  :global(.hljs-string) {
    color: theme("colors.slate.900");
  }
}
