@use "src/scss/globals" as *;

.spinner {
  .widget {
    @media (prefers-reduced-motion) {
      display: none;
    }
  }
  animation: spin 1s linear infinite;

  // Show a gentler pulsing motion if the user has motion reduction enabled
  @media (prefers-reduced-motion) {
    animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulse {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
