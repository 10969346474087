@use "src/scss/globals" as *;

.rainbow {
  background: linear-gradient(
    91.63deg,
    #dadaf1 -1.6%,
    #e9e2ee 25.29%,
    #f1e1df 44.5%,
    #efe4d2 53.83%,
    #cfe3e8 70.84%,
    #c1b7df 103.77%
  );
}

.rainbowSoft {
  background: linear-gradient(
      130deg,
      rgb(245, 247, 249),
      rgba(218, 218, 241, 0) 49.83%,
      rgba(218, 218, 241, 0) 49.83%,
      rgba(218, 218, 241, 0.39) 67.75%,
      rgba(233, 226, 238, 0.39) 76.12%,
      rgba(241, 225, 223, 0.39) 80.42%,
      rgba(239, 228, 210, 0.39) 84.72%,
      rgba(207, 227, 232, 0.39) 88.79%,
      rgba(193, 183, 223, 0.39) 97.63%
    ),
    var(--Color-Surface-Secondary, #f7f7f8);
}

.rainbowText {
  background: linear-gradient(
    42.36deg,
    #323281 27.99%,
    #8f6aab 40.29%,
    #d87c71 49.08%,
    #bd8725 53.35%,
    #1a97b3 73.55%,
    #7b54eb 76.2%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}

// Washed off version of the rainbow
.rainbowBlur {
  background: linear-gradient(
    90deg,
    #cbc6cd 0%,
    #cac6cb 23.96%,
    #c2c2c8 56.25%,
    #bbbec7 100%
  );
}

.rainbowButton {
  $border: 1px;

  background: linear-gradient(
    95deg,
    rgba(145, 145, 208, 0.5) -7.46%,
    rgba(180, 163, 198, 0.5) 22.22%,
    rgba(191, 156, 82, 0.5) 35.7%,
    rgba(189, 189, 104, 0.5) 51.98%,
    rgba(131, 190, 208, 0.5) 65.64%,
    rgba(146, 121, 234, 0.5) 105.47%
  ) !important;

  &::before {
    position: absolute;
    border-radius: 10px;
    width: calc(100% - 2 * $border);
    height: calc(100% - 2 * $border);
    top: $border;
    left: $border;
    border-radius: 7px;
    transition: all 50ms ease;
    content: "";

    background-color: white;
  }

  &:hover {
    $border: 2px;
    background: linear-gradient(
      90.74deg,
      rgba(145, 145, 208, 0.5) -7.46%,
      rgba(180, 163, 198, 0.5) 22.22%,
      rgba(191, 156, 82, 0.5) 35.7%,
      rgba(189, 189, 104, 0.5) 51.98%,
      rgba(131, 190, 208, 0.5) 65.64%,
      rgba(146, 121, 234, 0.5) 105.47%
    ) !important;

    &::before {
      border-radius: 6px;
      width: calc(100% - 2 * $border);
      height: calc(100% - 2 * $border);
      top: $border;
      left: $border;
    }
  }
}

.rainbowAnimation {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;

  &::before {
    position: absolute;
    border-radius: 12px;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      91.63deg,
      #acacdf -1.6%,
      #b8a2c8 12.84%,
      #c1a19c 25.29%,
      #dab2ab 34.17%,
      #a0d7e3 44.5%,
      #a4c3ca 53.83%,
      #b4a8d4 70.84%,
      #9dc4cb 87.31%,
      #d3ada7 103.77%,
      #e3c2bc 112.16%,
      #b8a2c8 125.84%,
      #a6a6db 142.16%
    );

    transition:
      background-position 0.5s,
      background-size 0.5s;

    animation: shiftBackground 2s ease infinite alternate;
    background-size: 200% 200%;
  }

  &::after {
    content: " ";
    transition: all 0.5s;
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    position: absolute;
    top: 2px;
    left: 2px;
    background-color: #f2eef5bb;
    border-radius: 11px;
    z-index: 1;
  }
}

@keyframes shiftBackground {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.rainbowAnimationPaused {
  &::before {
    animation-play-state: paused;
  }
  &::after {
    width: calc(100% - 8px);
    height: calc(100% - 8px);
    top: 4px;
    left: 4px;
    background-color: #fcfafd;
    z-index: 1;
    border-radius: 8px;
  }
}

.spin {
  animation: spin 1s linear infinite;
}

.spinPause {
  animation-play-state: paused;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
