:global(.dark) {
  .pillOperational {
    fill: #286d3b;
    background-color: #286d3b;

    &.active,
    &:hover {
      fill: #37884f;
      background-color: #37884f;
    }
  }

  .pillNoData {
    fill: theme("colors.slate.800");
    background-color: theme("colors.slate.800");

    &.active,
    &:hover {
      fill: theme("colors.slate.700");
      background-color: theme("colors.slate.700");
    }
  }

  .pillDegradedPerformance {
    fill: #c1a92e;
    background-color: #c1a92e;

    &.active,
    &:hover {
      fill: #d5c23e;
      background-color: #d5c23e;
    }
  }

  .pillPartialOutage {
    fill: #cf7328;
    background-color: #cf7328;

    &.active,
    &:hover {
      fill: #e08e37;
      background-color: #e08e37;
    }
  }

  .pillFullOutage {
    fill: #d65135;
    background-color: #d65135;

    &.active,
    &:hover {
      fill: #e56947;
      background-color: #e56947;
    }
  }

  .pillUnderMaintenance {
    fill: #438dbd;
    background-color: #438dbd;

    &.active,
    &:hover {
      fill: #a1c6de;
      background-color: #a1c6de;
    }
  }
}

.pillOperational {
  fill: #84da83;
  background-color: #84da83;

  &.active,
  &:hover {
    fill: #31a350;
    background-color: #31a350;
  }
}

.pillNoData {
  fill: theme("colors.slate.200");
  background-color: theme("colors.surface.secondary");

  &.active,
  &:hover {
    fill: theme("colors.slate.300");
    background-color: theme("colors.slate.300");
  }
}

.pillDegradedPerformance {
  fill: #efd13d;
  background-color: #efd13d;

  &.active,
  &:hover {
    fill: #c9ac1d;
    background-color: #c9ac1d;
  }
}

.pillPartialOutage {
  fill: #f9a764;
  background-color: #f9a764;

  &.active,
  &:hover {
    fill: #ec842e;
    background-color: #ec842e;
  }
}

.pillFullOutage {
  fill: #f57a62;
  background-color: #f57a62;

  &.active,
  &:hover {
    fill: #d65135;
    background-color: #d65135;
  }
}

.pillUnderMaintenance {
  fill: #57ace2;
  background-color: #57ace2;

  &.active,
  &:hover {
    fill: #2585c3;
    background-color: #2585c3;
  }
}
