@use "src/scss/globals" as *;
.pausedCompactCard {
  background: repeating-linear-gradient(
    135deg,
    #f8f8f8,
    #f8f8f8 10px,
    theme("colors.surface.primary") 10px,
    theme("colors.surface.primary") 20px
  );
}
