@use "src/scss/globals" as *;

.anchored {
  animation: blinker 1.5s linear;

  @keyframes blinker {
    0% {
      background-color: theme("colors.amber.100");
    }
  }
}
