.tabWrapper {
  &:not(:last-child) {
    margin-right: 1rem;
  }
}

.tab {
  // Basic styles
  @apply transition;

  display: inline-flex;
  align-items: center;
  padding-bottom: calc(0.5rem - 2px);
  border-bottom: 2px solid transparent;

  .icon {
    @apply transition;
  }

  // Lightmode!
  // Basic state: quite light
  @apply text-content-tertiary;
  .icon {
    @apply text-content-tertiary;
  }

  // When active, add a border on the bottom, and make everything darker
  &[data-state="active"] {
    @apply border-slate-800;

    @apply text-content-primary;
    .icon {
      @apply text-slate-600;
    }
  }

  // When hovering, make the whole lot dark
  &:hover {
    @apply text-content-primary;

    .icon {
      @apply text-content-primary;
    }
  }
}

:global(.dark) {
  .tab {
    // Basic state: also quite light
    @apply text-slate-500;
    .icon {
      @apply text-slate-600;
    }

    // When active, add a border on the bottom, and make everything lighter
    &[data-state="active"] {
      @apply border-slate-50;

      @apply text-slate-50;
      .icon {
        @apply text-slate-200;
      }
    }

  // When hovering, make the whole lot dark
    &:hover {
      @apply text-slate-50;

      .icon {
        @apply text-slate-50;
      }
    }
  }
}
