// This is the overlay that fades out the content behind the modal
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;
}

// This is the modal box
.dialogContent {
  position: relative;
  padding: 0 !important;
  display: flex;
  flex-direction: column;
  border-radius: 0.5rem;
  text-align: left;
  overflow: hidden;
  z-index: 100;

  width: 50vw;
  margin: 10vh auto;
  padding: 2rem;
  outline: none;
}

.closeButton {
  border-radius: 9999px;
  justify-content: center;
  svg {
    margin: 0 !important;
  }
}

.modalHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
}

.modalContent {
  width: 100%;
  max-height: 100%;
  overflow-y: auto;
  text-align: left;
}

.modalFooter {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  button + button {
    margin-left: 1rem;
  }
}
