.animatedTooltipContent {
  transform-origin: var(--radix-tooltip-content-transform-origin);
  animation: scaleIn 0.1s ease-out;
  z-index: 50;
}

@keyframes scaleIn {
  from {
    opacity: 0;
    transform: scale(0.9, 0.9);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
